import React, { useContext, useState } from 'react';

import {
  ControlMenu,
  ChannelCircleListView,
  Button,
  ModalBackground,
  ResizableWindow,
  ChannelSearchByGeoLocation,
  ChannelRelationshipsSearch,
  IconButton,
  FileInput,
  AddTenantRelationship,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  deleteChannelRelationship,
  addChannelRelationship,
} from 'lane-shared/graphql/mutation';
import { pause, getDisplayName, hasPermission } from 'lane-shared/helpers';
import {
  OFFICE_TYPES,
  RETAIL_TYPES,
  TENANT_TYPES,
} from 'lane-shared/helpers/constants/channel';
import {
  PERMISSION_ADMIN,
  PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_PROPERTY_MANAGE_RETAIL,
  PERMISSION_PROPERTY_CSV_UPLOAD,
} from 'lane-shared/helpers/constants/permissions';
import { shortAddress } from 'lane-shared/helpers/formatters';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import LocationType from 'lane-shared/properties/baseTypes/Location';
import {
  ChannelRelationshipTypeEnum,
  ChannelTypeEnum,
} from 'lane-shared/types/ChannelType';

import {
  createAutoSetupConfiguration,
  uploadCSVMutation,
} from 'graphql-queries';

import { AdminPage } from 'components/layout';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  addNewConfirmTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.title',
  addNewConfirmMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.message',
  addNewConfirmText:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.confirmText',
  addNewSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewSuccessToast',
  addNewErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.title',
  addNewErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.message',
  removeConfirmTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.title',
  removeConfirmMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.message',
  removeConfirmText:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.confirmText',
  removeSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeSuccessToast',
  removeErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeError.title',
  removeErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeError.message',
  uploadSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadSuccessToast',
  uploadErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadError.title',
  uploadErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadError.message',
  createNewTenantButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.createNewTenantButton',
  csvImportButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.csvImportButton',
  uploadCSVButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadCSVButton',
  addButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addButton',
};

export function PropertyChannelRelationships() {
  const { user, hasAnyPermission } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();
  const [type, setType] = useState<ChannelTypeEnum | 'All'>();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCsvOpen, setIsCsvOpen] = useState(false);
  const showNewTenantManagementView = useFlag(
    FeatureFlag.TenantManagement,
    false
  );

  const { t } = useTranslation();

  async function addNewRelationship(newChannel: any) {
    try {
      const name = getDisplayProfileName(newChannel);
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.addNewConfirmTitle, {
          channelName: name,
        }),
        message: t(TRANSLATION_KEYS.addNewConfirmMessage, {
          channelName: newChannel.name,
          targetChannel: channel?.name,
        }),
        confirmText: t(TRANSLATION_KEYS.addNewConfirmText),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const relationship = {
      channel: {
        _id: newChannel._id,
        parent: { _id: newChannel.parent._id },
      },
      relatedTo: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: channel._id,
      },
      type: ChannelRelationshipTypeEnum.Tenant,
    };

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: addChannelRelationship,
        variables: { relationship },
        refetchQueries: ['ChannelsByRelationship'],
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.addNewSuccessToast, {
            channelName: newChannel.name,
          })}
        </p>
      );
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.addNewErrorTitle, {
          channelName: getDisplayName(newChannel),
        }),
        message: t(TRANSLATION_KEYS.addNewErrorMessage),
        error: err,
      });
    }

    setIsOpen(false);
    setLoading(false);
  }

  async function removeRelationship(relationship: any) {
    try {
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.removeConfirmTitle, {
          channelName: getDisplayName(relationship.channel),
        }),
        message: t(TRANSLATION_KEYS.removeConfirmMessage, {
          channelName: getDisplayName(relationship.channel),
        }),
        confirmText: t(TRANSLATION_KEYS.removeConfirmText),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: deleteChannelRelationship,
        variables: { id: relationship._id },
        refetchQueries: ['ChannelsByRelationship'],
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.removeSuccessToast, {
            channelName: getDisplayName(relationship.channel),
          })}
        </p>
      );
    } catch (err: any) {
      await window.Alert.show({
        title: t(TRANSLATION_KEYS.removeErrorTitle, {
          channelName: getDisplayName(relationship.channel),
        }),
        message: t(TRANSLATION_KEYS.removeErrorMessage, {
          errorMessage: err.message,
        }),
      });
    }

    setLoading(false);
  }

  function getLink(rel: any) {
    const hasRetailAdmin =
      RETAIL_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_RETAIL,
      ]);

    const hasOfficeAdmin =
      OFFICE_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_COMPANY,
      ]);

    if (user?.isSuperUser || hasRetailAdmin || hasOfficeAdmin) {
      return routes.channelAdmin.replace(':id', rel.slug);
    }

    return routes.channel.replace(':id', rel.slug);
  }

  const handleFileUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await getClient().mutate({
        mutation: uploadCSVMutation,
        variables: {
          uploadCSVData: {
            text: file,
            contentType: 'text/csv',
            fileName: name,
          },
        },
      });

      if (data.uploadCSV.fileUrl) {
        await getClient().mutate({
          mutation: createAutoSetupConfiguration,
          variables: {
            autoSetupData: {
              source: 'csv',
              sourceLocations: [data.uploadCSV.fileUrl],
              parentChannelName: channel && channel.name,
              parentChannelId: channel && channel._id,
              tenantsOnly: true,
            },
          },
        });

        window.Toast.show(t(TRANSLATION_KEYS.uploadSuccessToast));
        setIsCsvOpen(false);
      }
    } catch (e) {
      window.Alert.show({
        title: t(TRANSLATION_KEYS.uploadErrorTitle),
        message: t(TRANSLATION_KEYS.uploadErrorMessage),
        error: e,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminPage className={styles.ChannelRelationships}>
      <ControlMenu>
        <hr />
        {user?.isSuperUser && (
          <Link to="relationships/new">
            <Button
              onClick={() => setIsOpen(true)}
              loading={loading}
              dataCy="createTenantButton"
              variant="contained"
            >
              {t(TRANSLATION_KEYS.createNewTenantButton)}
            </Button>
          </Link>
        )}
        {hasAnyPermission([PERMISSION_PROPERTY_CSV_UPLOAD], channel?._id) && (
          <Button
            onClick={() => setIsCsvOpen(true)}
            loading={loading}
            variant="contained"
          >
            {t(TRANSLATION_KEYS.csvImportButton)}
          </Button>
        )}
        {user?.isSuperUser && (
          <Button
            onClick={() => setIsOpen(true)}
            disabled={!showNewTenantManagementView && type === 'All'}
            loading={loading}
            dataCy="addTenantButton"
            variant="contained"
          >
            {t(TRANSLATION_KEYS.addButton)}
          </Button>
        )}
      </ControlMenu>
      <ChannelRelationshipsSearch
        channelId={channel?._id}
        types={TENANT_TYPES}
        onTypeChanged={(type: any) => setType(type)}
        renderRelationship={relationship => (
          <div className={styles.channel} key={relationship._id}>
            <Link to={getLink(relationship.channel)}>
              <ChannelCircleListView
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
                channel={relationship.channel}
                description={shortAddress(relationship.channel.address!)}
              />
            </Link>
            <IconButton
              dataCy="removeTenant"
              icon="times"
              inverted
              disabled={loading}
              className={styles.remove}
              onClick={() => removeRelationship(relationship)}
            />
          </div>
        )}
      />
      <ModalBackground
        className={styles.background}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'name' is missing in type '{ children: El... Remove this comment to see the full error message */}
        <ResizableWindow
          className={styles.window}
          defaultPosition={ResizableWindow.centerPosition()}
        >
          {!showNewTenantManagementView && <ChannelSearchByGeoLocation
            defaultDistance={200}
            type={type}
            excludeParentChannels
            location={{
              latitude:
                channel?.address?.geo?.[1] ?? LocationType.default.latitude,
              longitude:
                channel?.address?.geo?.[0] ?? LocationType.default.longitude,
            }}
            onChannelSelected={channel => addNewRelationship(channel)}
          />}
          {showNewTenantManagementView && (
            <AddTenantRelationship
              setIsOpen={setIsOpen}
              setLoading={setLoading}
            />
          )}
        </ResizableWindow>
        
      </ModalBackground>
      <ModalBackground
        className={styles.background}
        isOpen={isCsvOpen}
        onClose={() => setIsCsvOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          defaultPosition={ResizableWindow.centerPosition()}
          name="csv-tenant-upload"
        >
          <FileInput
            accept="text/csv"
            type={FileReturnTypeEnum.Text}
            onFileSelected={handleFileUpload}
          >
            <Button
              style={{ marginLeft: '2rem', marginBottom: '1rem' }}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.uploadCSVButton)}
            </Button>
          </FileInput>
        </ResizableWindow>
      </ModalBackground>
    </AdminPage>
  );
}
