import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import { Flex, CreatedBy, Loading } from 'lane-web/src/components';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import { ButtonType } from 'lane-web/src/components/layout/PageHeader';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { H4, S } from 'components/typography';
import styles from './styles.scss';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  OFFICE_TYPES,
  RETAIL_TYPES,
} from 'lane-shared/helpers/constants/channel';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_PROPERTY_MANAGE_RETAIL,
} from 'lane-shared/helpers/constants/permissions';
import { UserDataContext } from 'lane-shared/contexts';

const TOASTER_SECONDS = 5000;

export function TenantDetails() {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { user } = useContext(UserDataContext);

  const { tenantId } = useParams<{ tenantId: string }>();

  const [fetchRelationships, { data, error }] = useLazyQuery(queryChannelsByRelationship, {
    fetchPolicy: 'network-only',
  });

  const tenant = data?.channelsByRelationship?.items?.[0]?.channel as ChannelType;

  useEffect(() => {
    if (channel?._id) {
      fetchRelationships({
        variables: {
          search: {
            relatedTo: { _id: convertToUUID(channel?._id) },
            channel: {
              _id: convertToUUID(tenantId),
            },
          },
          skipPagination: true,
        },
      });
    }
  }, [channel, fetchRelationships]);

  if(error){
    window.Toast.show(t('web.admin.channel.settings.tenantManagement.tenant.details.error'), TOASTER_SECONDS);
    return null;
  }

  if(!tenant) {
    return <Loading fullscreen />;
  }

  function goToChannel(rel: any) {
    const hasRetailAdmin =
      RETAIL_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_RETAIL,
      ]);
    const hasOfficeAdmin =
      OFFICE_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_COMPANY,
      ]);

    let route : string;
    if (user?.isSuperUser || hasRetailAdmin || hasOfficeAdmin) {
      route = routes.channelAdmin.replace(':id', rel.slug);
    } else {
      route = routes.channel.replace(':id', rel.slug);
    }
    history.push(route);
  }

  const actionButton = {
    label: `${t('web.admin.channel.settings.tenantManagement.tenant.details.goto')} ${tenant?.name}`,
    type: 'primary' as ButtonType,
    onClick: () => goToChannel(tenant),
  };

  const tenantAddress = (
    <span className="tenant-location">
      <S className="block">{tenant?.address?.street1} {tenant?.address?.street2} </S>
      <S className="block">{tenant?.address?.street3}</S>
      <S>{tenant?.address?.city}, {tenant?.address?.state} , {tenant?.address?.code}</S>
    </span>
  );
  
  const createdBy = {
    "_createdBy": tenant._createdBy,
    "_updatedBy": tenant._updatedBy,
    "_created": tenant._created,
    "_updated": tenant._updated,
  } as any;
  
  return (
    <AdminPage>
      <PageHeader
        header={tenant?.name}
        headerLevel="h3"
        actionButtons={[actionButton]}
        externalPadding={[0, 0]}
        breadcrumbs={[
          {
            label: t('web.admin.channel.settings.tenantManagement.name'),
            url: routes.channelAdminRelationships.replace(
              ':id',
              channel?.slug || ''
            ),
          },
          {
            label: `${tenant?.name || ''} ${t('web.admin.channel.settings.tenantManagement.tenant.details')}`,
          },
          ]}
          description={tenantAddress}
        />

      <div className="mt-4">
        <div className={styles.tenantOverview}>
          <H4 mb={4}>{t`web.admin.channel.settings.tenantManagement.tenant.details.overview`}</H4>
          <Flex direction="column">
            <Flex className={styles.tenantOverviewDataRows}>
              <Flex className={styles.tenantOverviewDataRow}>
                  <span>{t('web.admin.channel.settings.tenantManagement.tenant.details.activeMembers')} </span>
                  <span>{tenant?.stats?.subscribers}</span>
              </Flex>
              <Flex className={styles.tenantOverviewDataRow}>
                <span>{t('web.admin.channel.settings.tenantManagement.tenant.details.type')}</span>
                <div className={styles.users}>
                  {tenant?.type}
                </div>
              </Flex>
              <Flex className={styles.tenantOverviewDataRow}>
                <span>{t('web.admin.channel.settings.tenantManagement.tenant.details.squareFootage')}</span>
                <div className={styles.users}>
                  {tenant?.stats?.sf } {t('web.admin.channel.settings.tenantManagement.tenant.details.sqFt')}
                </div>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>
      <div className={styles.createdByFooter}>
        <CreatedBy object={createdBy} forAdmin />
      </div>
    </AdminPage>
  );
}
