import React, { useEffect, useState } from 'react';

import { Flex, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { EntityTypeEnum } from 'lane-shared/types/attachment';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import ErrorMessage from 'lane-web/src/components/general/ErrorMessage';
import { BreadCrumbs } from 'lane-web/src/components/lds';

import { Equipment } from 'graphql-query-contracts';

import {
  createPMScheduleMutation,
  getAllEquipmentQuery,
  updatePMScheduleMutation,
} from 'graphql-queries';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CorePMSchedule } from 'activate-modules/work-order/schedule/server/interfaces/coreModels';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CreateScheduleResponseDTO,
  UpdateScheduleRequestDTO,
} from 'activate-modules/work-order/schedule/types';

import {
  AttachmentData,
  SelectFileReturnType,
} from '../../components/PMAttachments';
import { ScheduleForm } from '../../components/ScheduleForm';
import { ScheduleEditor } from '../../components/ScheduleEditor';
import { FileReturnType } from 'helpers/fileReaderResolver';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';

import styles from './index.scss';
import { useLazyQuery } from '@apollo/client';

interface IScheduleCreatePageProps {
  channel: any;
}

export function ScheduleCreatePage({ channel }: IScheduleCreatePageProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [attachmentFiles, setAttachmentFiles] = useState<AttachmentData[]>([]);
  const newScheduleCreateForm = useFlag(
    FeatureFlag.InteractiveStepsCreate,
    false
  );

  const [fetchEquipment, { data, loading }] = useLazyQuery(
    getAllEquipmentQuery,
    {
      onCompleted: () => {
        if (data?.getAllEquipment) {
          setEquipments(data.getAllEquipment);
        }
      },
    }
  );

  const preventiveMaintenancePath = routes.channelAdminWorkOrdersPMSchedules.replace(
    ':id',
    channel?.slug
  );

  useEffect(() => {
    if (channel?._id) {
      fetchEquipment({
        variables: {
          channelId: channel._id,
        },
      });
    }
  }, [channel?._id, fetchEquipment]);

  const onCancel = () => {
    history.push(preventiveMaintenancePath);
  };

  const { uploading: _, filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated: () => null,
    entity: {
      type: EntityTypeEnum.PMSchedule,
      id: '',
    },
  });

  const saveAttachments = async (scheduleId: string) => {
    let attachmentIds: string[] = [];
    try {
      const attachmentReturn = await filesSelectedHandler(
        attachmentFiles.map(attachment => attachment.file as File),
        scheduleId
      );
      attachmentIds = attachmentReturn.map(({ id }) => safeConvertToUUID(id));
    } catch (error: any) {
      // setLoading(false);
    }
    return attachmentIds;
  };

  const createNewSchedule = async (data: CorePMSchedule) => {
    const createPMSchedule = data as CorePMSchedule;
    try {
      const schedule = await getClient().mutate({
        mutation: createPMScheduleMutation,
        variables: {
          createPMSchedule,
        },
      });

      // upload and update schedule if attachment files exists
      if (attachmentFiles.length > 0) {
        const {
          __typename,
          ...scheduleData
        } = schedule!.data!.createPMSchedule;
        const attachmentIds = await saveAttachments(scheduleData.scheduleId);

        const {
          createdBy: _createdBy,
          updatedBy: _updatedBy,
          createdAt: _createdAt,
          updatedAt: _updatedAt,
          extRefId: _extRefId,
          isArchived: _isArchived,
          ...rest
        } = scheduleData as CreateScheduleResponseDTO;

        const dataToSave: UpdateScheduleRequestDTO = {
          ...rest,
          attachments: attachmentIds,
          scheduleId: scheduleData.scheduleId,
          assignee: scheduleData.assignee?.id ?? '',
          assigneeGroups: createPMSchedule.assigneeGroups ?? [],
        };

        await getClient().mutate({
          mutation: updatePMScheduleMutation,
          variables: {
            updatePMSchedule: dataToSave,
          },
        });
      }
      history.push(preventiveMaintenancePath);
      window.Toast.show(
        t(
          'web.admin.workOrder.preventiveMaintenance.schedule.scheduleCreate.success'
        )
      );
    } catch (err: any) {
      console.error(err);
      history.push(preventiveMaintenancePath);
      window.Toast.show(
        <ErrorMessage
          error={t(
            'web.admin.workOrder.preventiveMaintenance.schedule.scheduleCreate.failure'
          )}
        />
      );
    }
  };

  const addAttachmentFiles = (files: File[]) => {
    const attachmentsToAdd = files.map((file: FileReturnType) => ({
      file: file as any,
      thumbnailSignedUrl: URL.createObjectURL(file as File),
    }));

    const attachFiles = [...attachmentFiles, ...attachmentsToAdd];
    setAttachmentFiles(attachFiles);
  };

  const deleteAttachment = async (index: string) => {
    const newAttachmentFiles = [...attachmentFiles];
    newAttachmentFiles.splice(Number(index), 1);
    setAttachmentFiles(newAttachmentFiles);
  };

  const breadcrumbLinks = [
    {
      label: `${t('web.admin.workOrder.preventiveMaintenance.title')}`,
      url: preventiveMaintenancePath,
    },
    {
      label: `${t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.create'
      )}`,
    },
  ];

  if (loading) return <Loading fullscreen />;

  return newScheduleCreateForm ? (
    <ScheduleEditor
      attachmentFiles={attachmentFiles}
      channel={channel}
      channelEquipments={equipments}
      onAddAttachments={addAttachmentFiles as SelectFileReturnType}
      onCancel={onCancel}
      onRemoveAttachment={deleteAttachment}
      onSaveSchedule={createNewSchedule}
      breadcrumbLinks={breadcrumbLinks}
      title={t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.create'
      )}
    />
  ) : (
    <Flex direction="column" width="full" className={styles.Container}>
      <BreadCrumbs links={breadcrumbLinks} />
      <div className={styles.Form}>
        <ScheduleForm
          title={t(
            'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.create'
          )}
          channel={channel}
          channelEquipments={equipments}
          attachmentFiles={attachmentFiles}
          onAddAttachments={addAttachmentFiles as SelectFileReturnType}
          onRemoveAttachment={deleteAttachment}
          onSaveSchedule={createNewSchedule}
          onCancel={onCancel}
          saveButtonText={t(
            'web.admin.workOrder.preventiveMaintenance.schedule.Form.submit'
          )}
        />
      </div>
    </Flex>
  );
}
