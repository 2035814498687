import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { ValidationErrorContext } from 'lane-shared/contexts';
import { ValidationErrorContextType } from 'lane-shared/contexts/ValidationErrorContext';
import { PublicUserFragment } from 'lane-shared/graphql/fragments';
import {
  createChannelIntegration as createChannelIntegrationMutation,
  softDeleteChannelIntegration,
  updateChannelIntegration as updateChannelIntegrationMutation,
  updateChannelGroupRoles as updateChannelGroupRolesMutation,
  unPublishContent,
} from 'lane-shared/graphql/mutation';
import { pause, safeConvertToUUID } from 'lane-shared/helpers';
import constructProperty from 'lane-shared/helpers/content/constructProperty';
import getIntegrationDefinition from 'lane-shared/helpers/integrations/getIntegrationDefinition';
import {
  useFlag,
  useChannelServiceRequestFeaturesContext,
} from 'lane-shared/hooks';
import { createShapeFromProperties } from 'lane-shared/properties';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import 'lane-shared/types/baseTypes';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import ChannelIntegrationEditorAutoRenderer from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorAutoRenderer';
import {
  ChannelIntegration,
  ChannelIntegrationEditorProps,
} from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import { DeleteIntegrationModal } from 'components/integrations/ChannelIntegrationEditor/DeleteIntegrationModal';
import AdminPage from 'components/layout/AdminPage';

import { history, validateNestedConfig } from '../../helpers';
import ChannelIntegrationEditorRenderers from '../integrations/ChannelIntegrationEditor';
import IntegrationDropdown from './IntegrationDropdown';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './ChannelIntegrationEdit.scss';
import { CHANNEL_INTEGRATION_PERMISSION_UPDATES } from 'lane-shared/helpers/constants/permissions';
import { TESTABLE_INTEGRATIONS_CONFIG } from 'lane-shared/helpers/constants/integrations/Common';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { AccessControlServiceEntity } from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import { useQuery } from '@apollo/client';
import { contentsForIntegration } from 'lane-shared/graphql/content';
import type { Content } from 'graphql-query-contracts';
import { Button, Flex, H3 } from 'design-system-web';
import { Chip, ChipStyle } from 'components/ads';

const getIntegration = gql`
  ${PublicUserFragment}

  query getIntegrationForEdit($channelId: UUID!, $id: UUID!) {
    availableIntegrations(channelId: $channelId, search: { _id: $id }) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      name
      category
      type
      settings
      properties
      platforms
      workflows
    }
  }
`;

const verifyConfigQuery = gql`
  query VerifyConfig($provider: String!, $channelId: UUID!, $config: JSON!) {
    verifyConfig(provider: $provider, channelId: $channelId, config: $config) {
      data
    }
  }
`;

type TenantMappings = {
  vtsTenantId: string;
  geneaTenantId?: string;
  prevIgsValue?: string;
  vtsTenantIDs?: string[];
};

export default function ChannelIntegrationEdit({
  channelIntegration,
  forCreate,
}: ChannelIntegrationEditorProps) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { refetchChannelFeatures } = useChannelServiceRequestFeaturesContext();
  const [_channelIntegration, setChannelIntegration] = useState<any>(null);
  const [integration, setIntegration] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [integrationIsDeleted, setIntegrationIsDeleted] = useState(false);
  const [isConfigActive, setIsConfigActive] = useState(!integrationIsDeleted);
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationErrorContextType>(null);
  const [isTestConfigButtonVisible, setIsTestConfigButtonVisible] = useState(
    false
  );
  const kastleVisitorFeatureEnabled = useFlag(
    FeatureFlag.KastleVisitorManagement,
    false
  );

  const definition = integration
    ? getIntegrationDefinition(integration.name)
    : null;

  const ChannelIntegrationEditorRenderer = integration
    ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ChannelIntegrationEditorRenderers[integration.name] ||
      ChannelIntegrationEditorAutoRenderer
    : null;

  const deleteIntegrationFeatureEnabled = useFlag(
    FeatureFlag.DeleteChannelIntegrationAccess,
    true
  );

  const geneaVisitorFeatureEnabled = useFlag(
    FeatureFlag.GeneaVisitorManagement,
    false
  );
  const kastleAppleWalletFFEnabled = useFlag(
    FeatureFlag.EnableKastleAppleWallet,
    false
  );

  if (definition?.name === IntegrationProviderEnum.Kastle) {
    // Hide Apple Wallet setting for kastle integration if the feature flag is disabled
    definition.properties.isAppleWalletEnabled.hidden = !kastleAppleWalletFFEnabled;
  }

  function updateChannelIntegration(props: ChannelIntegration) {
    setChannelIntegration((prevState: Partial<ChannelIntegration>) => ({
      ...prevState,
      ...props,
    }));
  }

  async function loadIntegration(id: LaneType.UUID) {
    setLoading(true);
    setIntegration(null);

    if (TESTABLE_INTEGRATIONS_CONFIG[integration?.name]) {
      setIsTestConfigButtonVisible(true);
    } else {
      setIsTestConfigButtonVisible(false);
    }

    const { data } = await getClient().query({
      query: getIntegration,
      variables: {
        id,
        channelId: channel?._id,
      },
    });

    setIntegration(data.availableIntegrations?.[0]);

    setLoading(false);
  }

  const { data: integrationContent } = useQuery<{
    contentsForIntegration: Content[];
  }>(contentsForIntegration, {
    variables: {
      _id: safeConvertToUUID(channelIntegration?._id),
    },
    skip: !channelIntegration?._id,
  });

  useEffect(() => {
    if (!channel?._id) return;
    // if there is an integration selected, and its not loaded,
    // or not the one that's loaded. load one.

    if (
      (channelIntegration?.integration?._id && !integration) ||
      channelIntegration?.integration?._id !== integration?._id
    ) {
      loadIntegration(channelIntegration.integration._id);
    }

    if (channelIntegration?._id) {
      setChannelIntegration(channelIntegration);
    }

    if (TESTABLE_INTEGRATIONS_CONFIG[channelIntegration?.integration?.name]) {
      setIsTestConfigButtonVisible(true);
    } else {
      setIsTestConfigButtonVisible(false);
    }

    if (
      channelIntegration?.deletedAt &&
      channelIntegration.deletedAt !== null
    ) {
      setIntegrationIsDeleted(true);
      setIsConfigActive(false);
    }
  }, [channelIntegration?._id, channel?._id]);

  function changeIntegration(integration: any) {
    setIntegration(integration);

    const definition = getIntegrationDefinition(integration.name);

    // setup the default settings of this integration based on its definition
    const settings = {};

    Object.entries(definition.properties).forEach(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ([key, property]) => (settings[key] = constructProperty(property))
    );

    updateChannelIntegration({
      settings,
      // @ts-expect-error ts-migrate(2740) FIXME: Type '{ _id: any; }' is missing the following prop... Remove this comment to see the full error message
      integration: {
        _id: integration._id,
      },
    });

    if (TESTABLE_INTEGRATIONS_CONFIG[integration?.name]) {
      setIsTestConfigButtonVisible(true);
    } else {
      setIsTestConfigButtonVisible(false);
    }
  }

  function validateChannelIntegrationSettings(): boolean {
    if (!definition) {
      return false;
    }

    try {
      const validation = yup
        .object()
        .shape(createShapeFromProperties(definition.properties));

      validation.validateSync(_channelIntegration.settings, {
        abortEarly: false,
      });

      if (definition.name === IntegrationProviderEnum.AccessManagement) {
        validateNestedConfig(_channelIntegration.settings);
      }

      setValidationError(null);

      return true;
    } catch (err) {
      setValidationError(err);
      return false;
    }
  }

  const convertShortUUIDToLongUUIDKastle = (channelIntegrationSetting: any) => {
    channelIntegrationSetting.accessControlSettings.tenantMappings = channelIntegrationSetting.accessControlSettings.tenantMappings.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ prevIgsValue, ...item }: TenantMappings) => {
        let vtsTenantId = item.vtsTenantId;

        if (vtsTenantId) {
          vtsTenantId = convertToUUID(item.vtsTenantId);
        }

        return {
          ...item,
          vtsTenantId,
        };
      }
    );
  };

  async function doCreate() {
    if (!validateChannelIntegrationSettings()) {
      // we have errors, abort mutation!
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const acsProviderValue =
        _channelIntegration?.settings?.accessControlService?.value;
      const channelIntegrationSetting = _channelIntegration?.settings;

      if (
        geneaVisitorFeatureEnabled &&
        acsProviderValue === AccessControlServiceEntity.Genea &&
        channelIntegrationSetting?.accessControlSettings?.tenantMappings
          ?.length > 0
      ) {
        channelIntegrationSetting.accessControlSettings.tenantMappings = channelIntegrationSetting.accessControlSettings?.tenantMappings.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ prevIgsValue, ...item }: TenantMappings) => {
            let vtsTenantId = item.vtsTenantId;
            if (vtsTenantId) {
              vtsTenantId = convertToUUID(item.vtsTenantId);
            }

            return {
              ...item,
              vtsTenantId,
            };
          }
        );
      }

      if (
        acsProviderValue === AccessControlServiceEntity.Genetec &&
        channelIntegrationSetting?.accessControlSettings
          ?.visitorAccessGroupMappings?.length > 0
      ) {
        channelIntegrationSetting.accessControlSettings.visitorAccessGroupMappings = channelIntegrationSetting.accessControlSettings.visitorAccessGroupMappings?.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ prevIgsValue, ...item }: TenantMappings) => {
            const vtsTenantIDs = item?.vtsTenantIDs?.map((id: string) =>
              convertToUUID(id)
            );

            return {
              ...item,
              vtsTenantIDs,
            };
          }
        );
      }

      if (
        kastleVisitorFeatureEnabled &&
        acsProviderValue === AccessControlServiceEntity.Kastle &&
        channelIntegrationSetting?.accessControlSettings?.tenantMappings
          ?.length > 0
      ) {
        convertShortUUIDToLongUUIDKastle(channelIntegrationSetting);
      }

      const toCreate = {
        channel: _channelIntegration.channel,
        integration: {
          _id: _channelIntegration.integration._id,
        },
        settings: channelIntegrationSetting,
      };

      await getClient().mutate({
        mutation: createChannelIntegrationMutation,
        variables: {
          channelIntegration: toCreate,
        },
      });

      window.Toast.show(
        t('web.admin.channel.integrations.create.success', {
          integrationName: integration.name,
        })
      );
      updateChannelGroupRoles(integration.name);
      setIsConfigActive(true);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      history.replace(`/l/channel/${channel.slug}/admin/integrations`);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  async function doSave() {
    if (!validateChannelIntegrationSettings()) {
      // we have errors, abort mutation!
      return;
    }

    try {
      setLoading(true);
      setError(null);

      await pause(500);

      const acsProviderValue =
        _channelIntegration?.settings?.accessControlService?.value;
      const channelIntegrationSetting = _channelIntegration?.settings;

      if (
        geneaVisitorFeatureEnabled &&
        acsProviderValue === AccessControlServiceEntity.Genea &&
        channelIntegrationSetting?.accessControlSettings?.tenantMappings
          ?.length > 0
      ) {
        channelIntegrationSetting.accessControlSettings.tenantMappings = channelIntegrationSetting.accessControlSettings?.tenantMappings.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ prevIgsValue, ...item }: TenantMappings) => {
            let vtsTenantId = item.vtsTenantId;

            if (vtsTenantId) {
              vtsTenantId = convertToUUID(item.vtsTenantId);
            }

            return {
              ...item,
              vtsTenantId,
            };
          }
        );
      }

      if (
        acsProviderValue === AccessControlServiceEntity.Genetec &&
        channelIntegrationSetting?.accessControlSettings
          ?.visitorAccessGroupMappings?.length > 0
      ) {
        channelIntegrationSetting.accessControlSettings.visitorAccessGroupMappings = channelIntegrationSetting.accessControlSettings.visitorAccessGroupMappings?.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ prevIgsValue, ...item }: TenantMappings) => {
            const vtsTenantIDs = item?.vtsTenantIDs?.map((id: string) =>
              convertToUUID(id)
            );

            return {
              ...item,
              vtsTenantIDs,
            };
          }
        );
      }

      if (
        kastleVisitorFeatureEnabled &&
        acsProviderValue === AccessControlServiceEntity.Kastle &&
        channelIntegrationSetting?.accessControlSettings?.tenantMappings
          ?.length > 0
      ) {
        convertShortUUIDToLongUUIDKastle(channelIntegrationSetting);
      }

      // If somebody disables the Apple Wallet feature flag later on, we need to disable it in the Kastle integration settings if it's enabled previously and user re-saves the integration
      if (
        !kastleAppleWalletFFEnabled &&
        definition?.name === IntegrationProviderEnum.Kastle &&
        channelIntegrationSetting?.isAppleWalletEnabled
      ) {
        channelIntegrationSetting.isAppleWalletEnabled = false;
      }

      const toSave = {
        _id: _channelIntegration._id,
        channel: _channelIntegration.channel,
        integration: {
          _id: _channelIntegration.integration._id,
        },
        settings: channelIntegrationSetting,
      };

      const update = await getClient().mutate({
        mutation: updateChannelIntegrationMutation,
        variables: {
          channelIntegration: toSave,
        },
      });
      updateChannelIntegration(update.data.updateChannelIntegration);
      updateChannelGroupRoles(integration.name);
      setIsConfigActive(true);
      window.Toast.show(
        t('web.admin.channel.integrations.update.success', {
          integrationName: integration.name,
        })
      );
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  async function onSave() {
    if (forCreate) {
      await doCreate();
    } else {
      await doSave();
    }
    refetchChannelFeatures();
  }

  const onTest = async () => {
    const config = _channelIntegration.settings;
    setError(null);
    setLoading(true);
    try {
      const integrationToVerify =
        TESTABLE_INTEGRATIONS_CONFIG[integration.name];
      if (!isEmpty(integrationToVerify.configFieldsToReplace)) {
        Object.entries(integrationToVerify.configFieldsToReplace).forEach(
          ([keyToReplace, originalKey]) => {
            config[keyToReplace] = config[originalKey];
          }
        );
      }

      await getClient().query({
        query: verifyConfigQuery,
        fetchPolicy: 'network-only',
        variables: {
          provider: integrationToVerify.provider,
          channelId: channel?._id,
          config,
        },
      });

      window.Toast.show(
        t('web.admin.channel.integrations.test.success', {
          integrationName: integration.name,
        })
      );
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  async function updateChannelGroupRoles(integrationName: string) {
    if (isEmpty(CHANNEL_INTEGRATION_PERMISSION_UPDATES[integrationName])) {
      return;
    }
    for (const intPermissions of CHANNEL_INTEGRATION_PERMISSION_UPDATES[
      integrationName
    ]) {
      const modulePermissions: string[] = intPermissions.permissions || [];
      try {
        const groupRole = {
          externalId: channel?._id,
          externalType: 'Channel',
          keys: ['externalId', 'externalType'],
          permissions: {
            [intPermissions.action]: modulePermissions,
          },
        };

        await getClient().mutate({
          mutation: updateChannelGroupRolesMutation,
          variables: {
            groupRole,
          },
        });
        window.Toast.show(t('web.admin.channel.update.group.role.success'));
      } catch (err) {
        setError(err);
      }
    }
  }

  async function deactivateIntegration() {
    const integrationName = definition?.friendlyName || 'integration';
    try {
      setIsDeleting(true);
      setIsActivating(true);

      await getClient().mutate({
        mutation: softDeleteChannelIntegration,
        variables: {
          id: channelIntegration._id,
        },
      });

      setIntegrationIsDeleted(true);

      if (
        integrationContent &&
        'contentsForIntegration' in integrationContent &&
        integrationContent.contentsForIntegration.length > 0
      ) {
        const { contentsForIntegration } = integrationContent;
        await Promise.all(
          contentsForIntegration.map(content => {
            return getClient().mutate({
              mutation: unPublishContent,
              variables: {
                id: safeConvertToUUID(content._id),
              },
            });
          })
        );
      }

      setChannelIntegration((prevChannelIntegration: any) => ({
        ...prevChannelIntegration,
        // perform an optimistic update by updating the integration
        // object in state and on page load the correct
        // integration object will replace the one in state
        deletedAt: new Date(),
      }));
      setIsActivating(false);
      setIsConfigActive(false);

      window.Toast.show(
        t('web.admin.channel.integrations.delete.success', { integrationName })
      );
    } catch (err) {
      const errorMessage =
        t('web.admin.channel.integrations.delete.failure', {
          integrationName,
        }) + err;
      window.Toast.show(errorMessage);
    } finally {
      setIsDeleting(false);
      setIsActivating(false);
    }
  }

  if (!_channelIntegration) {
    return <Loading />;
  }

  return (
    <AdminPage className={styles.ChannelIntegrationEdit}>
      <Flex mb={6}>
        {!forCreate &&
        _channelIntegration?.integration?.name === 'AccessManagement' ? (
          <Flex gap={2} align="end">
            <H3>{definition?.friendlyName || 'Integration'}</H3>
            <Chip
              size="xs"
              type={isConfigActive ? ChipStyle.Green : ChipStyle.Red}
              value={
                isConfigActive
                  ? 'web.admin.channel.integrations.status.active'
                  : 'web.admin.channel.integrations.status.inactive'
              }
            />
          </Flex>
        ) : null}
        <Flex gap={2} align="end" style={{ marginLeft: 'auto' }}>
          {deleteIntegrationFeatureEnabled &&
            !forCreate &&
            _channelIntegration?.integration?.name === 'AccessManagement' && (
              <Button
                loading={isActivating}
                dataCy="deleteIntegration"
                onClick={async () => {
                  if (integrationIsDeleted) {
                    setIntegrationIsDeleted(false);
                  } else {
                    setIsModalOpen(true);
                  }
                }}
                variant="secondary"
              >
                {integrationIsDeleted
                  ? t('web.admin.channel.integrations.button.activate')
                  : t('web.admin.channel.integrations.button.deactivate')}
              </Button>
            )}
          <Button
            dataCy="testIntegration"
            loading={loading}
            onClick={onTest}
            variant="secondary"
            style={{ display: isTestConfigButtonVisible ? 'block' : 'none' }}
          >
            {t`web.admin.channel.integrations.button.test`}
          </Button>
          <Button
            dataCy="createIntegration"
            loading={loading}
            onClick={() => onSave()}
            variant="primary"
            disabled={
              (forCreate && !_channelIntegration?.integration?._id) ||
              integrationIsDeleted
            }
          >
            {forCreate
              ? t`web.admin.channel.integrations.button.create`
              : t`web.admin.channel.integrations.button.save`}
          </Button>
        </Flex>
      </Flex>
      <ErrorMessage error={error} />
      {forCreate && (
        <section className={styles.edit}>
          <IntegrationDropdown
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            dataCy="selectIntegration"
            onChange={changeIntegration}
            value={_channelIntegration?.integration?._id}
            channel={channel}
          />
        </section>
      )}
      {definition && (
        <section className={styles.edit}>
          <ValidationErrorContext.Provider value={validationError}>
            <ChannelIntegrationEditorRenderer
              forCreate={forCreate}
              integrationIsDeleted={integrationIsDeleted}
              definition={definition}
              channel={channel}
              channelIntegration={_channelIntegration}
              onUpdateChannelIntegration={updateChannelIntegration}
            />
          </ValidationErrorContext.Provider>
        </section>
      )}
      <DeleteIntegrationModal
        isLoading={isDeleting}
        channelIntegration={_channelIntegration}
        channelIntegrationName={definition?.friendlyName || 'integration'}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onDeleteIntegration={() => deactivateIntegration()}
      />
    </AdminPage>
  );
}
