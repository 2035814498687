import React, { useEffect, useState } from 'react';

import { Icon, Input } from 'design-system-web';
import { compact, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { dateFormatter } from 'lane-shared/helpers/formatters';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { AttachmentResponse } from 'lane-shared/types/attachment';
import { meterReadingNotificationTrigger } from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingNotificationTrigger';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import {
  AttachmentThumbnail,
  Button,
  FileInput,
  Flex,
  Label,
  ValidationMessage,
} from 'lane-web/src/components';
import {
  HookFormDropdown,
  HookFormInput,
  HookFormTextarea,
} from 'lane-web/src/components/reactHookForm';
import { H4, M, P } from 'lane-web/src/components/typography';
import { EquipmentStatusOptions } from 'lane-web/src/domains/workOrder/equipment/utils/constants';
import { getEquipmentSettingsOnChannel } from 'graphql-queries';
import { v4 as uuid } from 'uuid';

import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import { validateWorkorder } from 'lane-shared/validation';
import styles from './EquipmentForm.scss';
import EquipmentURLForm from './EquipmentURLForm';
import MeterReadingSettings from './MeterReadingSettings';

type FormInputType = {
  name?: string | null;
  category?: string | null;
  location?: string | null;
  status?: string | null;
  notes?: string | null;
  make?: string | null;
  model?: string | null;
  asset?: string | null;
  serial?: string | null;
  floor?: string | null;
  suite?: string | null;
  installDate?: Date | null;
  warrantyExpirationDate?: Date | null;
  hyperlinks?: { name?: string; url?: string }[] | null;
  meterReadingSettings?: any[] | null;
};

type Props = {
  channel: any;
  loading: any;
  equipmentListPath?: string | any;
  formData: FormInputType;
  attachmentFiles: AttachmentResponse[];
  addAttachments: (files: File[]) => void;
  deleteAttachment: (index: string) => void;
  onSave: (data: any) => void;
  afterSave: () => void;
};

export const statusArray = [
  {
    label: 'web.admin.serviceRequest.equipment.status.active',
    value: EquipmentStatusOptions.ACTIVE,
  },
  {
    label: 'web.admin.serviceRequest.equipment.status.inactive',
    value: EquipmentStatusOptions.INACTIVE,
  },
];

const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

function EquipmentForm({
  channel,
  loading,
  formData,
  attachmentFiles,
  addAttachments,
  deleteAttachment,
  onSave,
  afterSave,
}: Props) {
  const [navigateAway, setNavigateAway] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [hyperlinks, setHyperlinks] = useState<
    { name?: string; url?: string }[]
  >([]);
  const [validated, setValidated] = useState<boolean>(false);

  const [installDate, setInstallDate] = useState<string>(
    dateFormatter(formData?.installDate, 'MM/dd/yyyy')
  );

  const [warrantyExpirationDate, setWarrantyExpirationDate] = useState<string>(
    dateFormatter(formData?.warrantyExpirationDate, 'MM/dd/yyyy')
  );

  const [meterReadingSettings, setMeterReadingSettings] = useState<any[]>([]);
  const [getEquipmentSettings] = useLazyQuery(getEquipmentSettingsOnChannel, {
    onCompleted: ({ equipmentSettingsOnChannel }) => {
      if (equipmentSettingsOnChannel) {
        const existingCategories = equipmentSettingsOnChannel.categories || [];
        const newCategories = compact([
          ...existingCategories,
          formData?.category,
        ]);

        const existingLocations = equipmentSettingsOnChannel.locations || [];
        const newLocations = compact([
          ...existingLocations,
          formData?.location,
        ]);
        setCategories(newCategories);
        setLocations(newLocations);
      }
    },
  });

  const handleAddMeterReadingSetting = () => {
    const newMeterReadingSettings = [...meterReadingSettings];
    newMeterReadingSettings.push({ tempId: uuid(), notificationTypes: [] });
    setMeterReadingSettings(newMeterReadingSettings);
  };

  const removeMeterReadingSetting = (index: number) => {
    setMeterReadingSettings(meterReadingSettings.filter((_, i) => i !== index));
  };

  const updateMeterReadingSetting = (
    index: number,
    meterReadingSetting: any
  ) => {
    const newMeterReadingSettings = [...meterReadingSettings];
    newMeterReadingSettings[index] = meterReadingSetting;
    setMeterReadingSettings(newMeterReadingSettings);
  };

  const handleAddHyperlink = () => {
    const newHyperlinks = [...hyperlinks];
    newHyperlinks.push({ name: undefined, url: undefined });
    setHyperlinks(newHyperlinks);
  };

  const handleRemoveHyperlink = (index: number) => {
    const newHyperlinks = [...hyperlinks];
    newHyperlinks.splice(index, 1);
    setHyperlinks(newHyperlinks);
  };

  const handleHyperlinkChange = (
    type: string,
    value: string,
    index: number
  ) => {
    if (type === 'name') {
      const newHyperlinks = [...hyperlinks];
      newHyperlinks[index]!.name = value;
      setHyperlinks(newHyperlinks);
    } else if (type === 'url') {
      const newHyperlinks = [...hyperlinks];
      newHyperlinks[index]!.url = value;
      setHyperlinks(newHyperlinks);
    }
  };

  useEffect(() => {
    if (channel?._id) {
      getEquipmentSettings({
        variables: { channelId: convertToUUID(channel?._id) },
      });
    }
  }, [channel?._id, getEquipmentSettings]);

  useEffect(() => {
    // set hyperlinks from form data
    if (formData?.hyperlinks && formData.hyperlinks.length > 0) {
      setHyperlinks(formData.hyperlinks);
    }
    // set meter reading settings from form data
    if (
      formData?.meterReadingSettings &&
      formData.meterReadingSettings.length > 0
    ) {
      setMeterReadingSettings(formData.meterReadingSettings);
    }
  }, [formData.hyperlinks, formData.meterReadingSettings]);

  const equipmentCategories = categories?.map((category: string) => {
    return { label: category, value: category };
  });
  const equipmentLocations = locations?.map((location: string) => {
    return { label: location, value: location };
  });

  const { register, handleSubmit, control, formState } = useForm<FormInputType>(
    {
      mode: 'all',
      defaultValues: formData,
    }
  );
  const { t } = useTranslation();
  const history = useHistory();

  const { urlValidator, stringValidator } = validateWorkorder(t);

  const checkDate = (date: string) => {
    return validated &&
      date &&
      (!datePattern.test(date) || Number.isNaN(new Date(date).getDate()))
      ? [t('web.admin.serviceRequest.equipment.dateInput.placeHolder')]
      : undefined;
  };

  const validate = () => {
    setValidated(true);
    if (hyperlinks.length > 0) {
      try {
        for (const hyperlink of hyperlinks) {
          if (hyperlink.name && hyperlink.url) {
            urlValidator.validateSync(hyperlink.url);
            stringValidator.validateSync(hyperlink.name);
          } else {
            return false;
          }
        }
      } catch (error) {
        return false;
      }
    }

    if (meterReadingSettings.length > 0) {
      try {
        for (const meterReadingSetting of meterReadingSettings) {
          if (meterReadingSetting.unit) {
            if (meterReadingSetting.enableNotification) {
              if (
                meterReadingSetting.notificationTypes.length !== 0 &&
                meterReadingSetting.notificationTrigger
              ) {
                if (
                  meterReadingSetting.notificationTrigger ===
                  meterReadingNotificationTrigger.LESS_THAN
                ) {
                  if (
                    meterReadingSetting.lowerBound === undefined ||
                    meterReadingSetting.lowerBound === null
                  ) {
                    return false;
                  }
                } else if (
                  meterReadingSetting.notificationTrigger ===
                  meterReadingNotificationTrigger.GREATER_THAN
                ) {
                  if (
                    meterReadingSetting.upperBound === undefined ||
                    meterReadingSetting.upperBound === null
                  ) {
                    return false;
                  }
                } else if (
                  meterReadingSetting.notificationTrigger ===
                  meterReadingNotificationTrigger.OUTSIDE_RANGE_OF
                ) {
                  if (
                    meterReadingSetting.lowerBound === undefined ||
                    meterReadingSetting.lowerBound === null ||
                    meterReadingSetting.upperBound === undefined ||
                    meterReadingSetting.upperBound === null
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        }
      } catch (error) {
        return false;
      }
    }

    if (
      installDate &&
      (!datePattern.test(installDate) ||
        Number.isNaN(new Date(installDate).getDate()))
    ) {
      return false;
    }
    if (
      warrantyExpirationDate &&
      (!datePattern.test(warrantyExpirationDate) ||
        Number.isNaN(new Date(warrantyExpirationDate).getDate()))
    ) {
      return false;
    }
    return true;
  };

  const formSubmission = async (data: any) => {
    if (!validate()) {
      return;
    }
    data.attachments = attachmentFiles.map(attachment => attachment.id);
    data.hyperlinks = hyperlinks;
    const meterReadingSettingsToSave = [...meterReadingSettings];
    meterReadingSettingsToSave.forEach(element => {
      if (element.tempId) {
        delete element.tempId;
      }
    });
    data.meterReadingSettings = meterReadingSettingsToSave;
    if (installDate) {
      data.installDate = new Date(installDate);
    } else {
      data.installDate = undefined;
    }
    if (warrantyExpirationDate) {
      data.warrantyExpirationDate = new Date(warrantyExpirationDate);
    } else {
      data.warrantyExpirationDate = undefined;
    }
    await onSave(data);
    afterSave();
  };

  if (!navigateAway && Object.keys(formState.errors).length > 0) {
    setNavigateAway(true);
  }

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  return (
    <form onSubmit={handleSubmit(formSubmission)} style={{ width: '100%' }}>
      <Flex gap={5} direction="column">
        <Flex className={styles.input} direction="column">
          <Label className={styles.inputHeadings} mt={0}>
            {t('web.admin.serviceRequest.equipment.name.title')}
            <span className={styles.required}>*</span>
          </Label>
          {/* @ts-ignore */}
          <HookFormInput
            control={control}
            isRequired
            name="name"
            errorMessage={
              formState.errors.name
                ? [t('web.admin.serviceRequest.field.required')]
                : null
            }
            dataCy="equipment-name"
            inputClassName="equipment-name"
          />
        </Flex>
        <Flex className={styles.input} direction="column">
          <Label className={styles.inputHeadings} mt={0}>
            {t('web.admin.serviceRequest.equipment.category.title')}
            <span className={styles.required}>*</span>
          </Label>
          <HookFormDropdown
            placeholder={t('web.admin.serviceRequest.equipment.select')}
            items={equipmentCategories}
            control={control}
            {...register('category', { required: true })}
            isFullWidth
          />
          {formState.errors.category && (
            <ValidationMessage
              className={styles.validationMessage}
              errors={[t('web.admin.serviceRequest.field.required')]}
              withoutIcon
            />
          )}
        </Flex>

        <Flex className={styles.inputDiv} gap={5}>
          <Flex className={styles.inputDiv1} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.status.title')}
              <span className={styles.required}>*</span>
            </Label>
            <HookFormDropdown
              placeholder={t('web.admin.serviceRequest.equipment.select')}
              items={statusArray}
              control={control}
              {...register('status', { required: true })}
              isFullWidth
            />
            {formState.errors.status && (
              <ValidationMessage
                className={styles.validationMessage}
                errors={[t('web.admin.serviceRequest.field.required')]}
                withoutIcon
              />
            )}
          </Flex>
          <Flex className={styles.inputDiv2} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.location.title')}
              <span className={styles.required}>*</span>
            </Label>
            <HookFormDropdown
              placeholder={t('web.admin.serviceRequest.equipment.select')}
              items={equipmentLocations}
              control={control}
              {...register('location', { required: true })}
              isFullWidth
            />
            {formState.errors.location && (
              <ValidationMessage
                className={styles.validationMessage}
                errors={[t('web.admin.serviceRequest.field.required')]}
                withoutIcon
              />
            )}
          </Flex>
        </Flex>

        {channel && isPropertyChannel && (
          <Flex className={styles.inputDiv} gap={5}>
            <Flex className={styles.inputDiv1} direction="column">
              <Label className={styles.inputHeadings} mt={0}>
                {t('web.admin.serviceRequest.equipment.floor.title')}
              </Label>
              <HookFormInput
                control={control}
                {...register('floor')}
                inputClassName="floor"
                className={styles.floorInput}
                type="text"
                disabled={false}
              />
            </Flex>
            <Flex className={styles.inputDiv2} direction="column">
              <Label className={styles.inputHeadings} mt={0}>
                {t('web.admin.serviceRequest.equipment.suite.title')}
              </Label>
              <HookFormInput
                control={control}
                {...register('suite')}
                inputClassName="suite"
                className={styles.suiteInput}
                type="text"
                disabled={false}
              />
            </Flex>
          </Flex>
        )}

        <Flex className={styles.inputDiv} gap={5}>
          <Flex className={styles.inputDiv1} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.make.title')}
            </Label>
            <HookFormInput
              control={control}
              {...register('make')}
              inputClassName="make"
              type="text"
              disabled={false}
            />
          </Flex>
          <Flex className={styles.inputDiv2} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.model.title')}
            </Label>
            <HookFormInput
              control={control}
              {...register('model')}
              inputClassName="model"
              type="text"
              disabled={false}
            />
          </Flex>
        </Flex>

        <Flex className={styles.inputDiv} gap={5}>
          <Flex className={styles.inputDiv1} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.serial.title')}
            </Label>
            <HookFormInput
              control={control}
              {...register('serial')}
              inputClassName="serial"
              type="text"
              disabled={false}
            />
          </Flex>
          <Flex className={styles.inputDiv2} direction="column">
            <Label className={styles.inputHeadings} mt={0}>
              {t('web.admin.serviceRequest.equipment.asset.title')}
            </Label>
            <HookFormInput
              control={control}
              {...register('asset')}
              inputClassName="asset"
              type="text"
              disabled={false}
            />
          </Flex>
        </Flex>

        <Flex direction="row" className={styles.datePickerContainer} gap={5}>
          <Flex direction="column" className={styles.datePickerSubContainer}>
            <Input
              value={warrantyExpirationDate}
              dataCy="equipment-warranty-expiration-date-input"
              inputClassName="warrantyExpirationDate"
              type="text"
              label={t(
                'web.admin.serviceRequest.equipment.warrantyExpirationDate.title'
              )}
              placeholder={t(
                'web.admin.serviceRequest.equipment.dateInput.placeHolder'
              )}
              fixedLabel
              onChange={(value: string) => {
                setWarrantyExpirationDate(value);
              }}
              error={checkDate(warrantyExpirationDate)}
            />
          </Flex>
          <Flex direction="column" className={styles.datePickerSubContainer}>
            <Input
              value={installDate}
              dataCy="equipment-install-date-input"
              inputClassName="installDate"
              type="text"
              label={t('web.admin.serviceRequest.equipment.installDate.title')}
              placeholder={t(
                'web.admin.serviceRequest.equipment.dateInput.placeHolder'
              )}
              fixedLabel
              onChange={(value: string) => {
                setInstallDate(value);
              }}
              error={checkDate(installDate)}
            />
          </Flex>
        </Flex>
        <Flex className={styles.input} direction="column">
          <Label className={styles.inputHeadings} mt={0}>
            {t('web.admin.serviceRequest.equipment.notes.title')}
          </Label>
          <HookFormTextarea
            containerClassName={styles.field}
            control={control}
            {...register('notes')}
            showLengthIndicator={false}
            minRows={4}
            maxLength={3_000}
            placeholder={t(
              'web.admin.serviceRequest.equipment.notes.placeholder'
            )}
          />
        </Flex>

        <div className={styles.lineBreak} />

        <Flex gap={5} className={styles.equipmentFormBlock} direction="column">
          <H4>{t('web.admin.serviceRequest.equipment.meterReadings.title')}</H4>

          <Flex direction="column" gap={4}>
            {meterReadingSettings.map((meterReadingSetting, index) => (
              <div
                key={`meterreading-${
                  meterReadingSetting.id
                    ? meterReadingSetting.id
                    : meterReadingSetting.tempId
                }`}
              >
                <MeterReadingSettings
                  meterReadingSetting={meterReadingSetting}
                  index={index}
                  onDelete={removeMeterReadingSetting}
                  onUpdate={updateMeterReadingSetting}
                  validated={validated}
                />
              </div>
            ))}
            <div>
              <Button
                variant="text-icon"
                startIcon={<Icon name="plus" />}
                onClick={handleAddMeterReadingSetting}
                fullWidth={false}
                size="small"
                dataCy="add-option"
              >
                {t('web.admin.serviceRequest.equipment.meterReadings.add')}
              </Button>
            </div>
          </Flex>
        </Flex>

        <div className={styles.lineBreak} />

        <Flex gap={5} className={styles.equipmentFormBlock} direction="column">
          <H4>
            {t('web.admin.serviceRequest.equipment.LinkedDocumentation.title')}
          </H4>

          <Flex direction="column" gap={3}>
            {hyperlinks.length > 0 && (
              <Flex className={styles.HyperlinkLabels}>
                <Label mt={0}>
                  {t(
                    'web.admin.serviceRequest.equipment.LinkedDocumentation.name'
                  )}
                  <span className={styles.required}>*</span>
                </Label>
                <Label mt={0}>
                  {t(
                    'web.admin.serviceRequest.equipment.LinkedDocumentation.url'
                  )}
                  <span className={styles.required}>*</span>
                </Label>
              </Flex>
            )}
            {hyperlinks.map((hyperlink, index) => (
              <EquipmentURLForm
                key={`hyperlink-${index}`}
                hyperlink={hyperlink}
                index={index}
                handleHyperlinkChange={handleHyperlinkChange}
                handleRemoveHyperlink={handleRemoveHyperlink}
                validated={validated}
              />
            ))}
            <div>
              <Button
                variant="text-icon"
                startIcon={<Icon name="plus" />}
                onClick={handleAddHyperlink}
                fullWidth={false}
                size="small"
                dataCy="add-option"
              >
                {t(
                  'web.admin.serviceRequest.equipment.LinkedDocumentation.add'
                )}
              </Button>
            </div>
          </Flex>
        </Flex>

        <div className={styles.lineBreak} />

        <Flex direction="column" className={styles.attachments} gap={2}>
          <H4>{t('web.admin.serviceRequest.equipment.attachments.title')}</H4>
          <P variant="secondary">
            {t('web.admin.serviceRequest.equipment.attachments.disclaimer')}
          </P>
          <FileInput
            accept="*/*"
            type={FileReturnTypeEnum.File}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(files: FileReturnType[]) => <void>' is not assign... Remove this comment to see the full error message
            onFilesSelected={addAttachments}
            enableMultiUpload
          >
            {/* TODO: this should not be recreating the styles of the AddAttachment component */}
            <Button
              style={{ width: '50%' }}
              className={styles.buttonAttachments}
              fullWidth={false}
            >
              <div className={styles.row}>
                <Icon
                  className={styles.emptyImageIcon}
                  set={ICON_SET_FONTAWESOME}
                  name="paperclip"
                  type="fal"
                />
                <M className={styles.buttonAttachmentsLabel}>
                  {t('Attach File or Photo')}
                </M>
              </div>
            </Button>
          </FileInput>
          <Flex className={styles.attachmentThumbnail} gap={4}>
            {attachmentFiles.map((attachment, index) => (
              <AttachmentThumbnail
                key={`attachment-${index}`}
                attachment={{ ...attachment, id: String(index) }}
                deleteAttachmentHandler={deleteAttachment}
              />
            ))}
          </Flex>
        </Flex>
        <Flex className={styles.buttonGroup}>
          <Button
            type="submit"
            variant="activate-contained"
            size="large"
            dataCy="submitFormButton"
            loading={loading}
            onClick={() => {
              setValidated(true);
              setNavigateAway(false);
            }}
            className={styles.formButton}
            labelClassName="submit-button"
          >
            {!isEmpty(formData.name)
              ? t('web.admin.serviceRequest.equipment.save')
              : t('web.admin.serviceRequest.equipment.submit')}
          </Button>
          <Button
            variant="outlined"
            size="large"
            dataCy="cancelFormButton"
            onClick={() => {
              setNavigateAway(false);
              history.goBack();
            }}
            className={styles.formButton}
            labelClassName="cancel-button"
          >
            {t('web.admin.serviceRequest.equipment.cancel')}
          </Button>
        </Flex>
        {navigateAway && (
          <Prompt
            when={formState.isDirty}
            message={t(
              'web.admin.serviceRequest.equipment.form.navigation.prompt'
            )}
          />
        )}
      </Flex>
    </form>
  );
}
export default EquipmentForm;
