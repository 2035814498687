import React, { useMemo, useState, useEffect } from 'react';
import {
  NativeFilterTypes,
  Table,
  QueryString,
  convertStringsToDates,
  getPageSizeFromQueryString,
} from 'design-system-web';

import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
  PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
} from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { Button, Flex } from 'lane-web/src/components';
import { ChipSelect, ChipStyle } from 'lane-web/src/components/ads';
import { EmptyPageView } from 'components/layout';

import { H3 } from 'lane-web/src/components/typography';
import { EquipmentStatusOptions } from 'lane-web/src/domains/workOrder/equipment/utils/constants';
import { exportCSV } from 'lane-web/src/domains/workOrder/helpers/exportCSV';
import { useQueryString, usePersistedParams } from 'lane-web/src/hooks';

import {
  getEquipmentForExport,
  getEquipmentSettingsOnChannel,
  searchEquipment,
} from 'graphql-queries';

import { statusArray } from '../form/EquipmentForm';

import styles from './EquipmentList.scss';
import { DangerousTranslate } from 'components/DangerousTranslate';

const PER_PAGE = 50;
const TOASTER_SECONDS = 5000;
const TABLE_STATE_STORAGE_VARIABLE = 'work-orders.equipment-table-state';

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'created_at',
  sortDirection: 'desc',
  // filters
  category: '',
  install_date: '',
  keyword: '',
  location: '',
  status: '',
  warranty_expiration_date: '',
};

type EquipmentQueryString = {
  keyword: string;
  total: number;
  created_at: string;
} & QueryString;

type EquipmentQueryResponse = {
  id: string;
  name: string;
  status: string[];
  location: string[];
  category: string[];
  identification: string;
  specification: string;
  notes: string;
};

function EquipmentList({ channel, user }: any) {
  const { t } = useTranslation();
  const dataImporterFlag = useFlag(FeatureFlag.DataImporter, false);
  const [equipments, setEquipments] = useState<any>(null);
  const [categories, setCategories] = useState<string[]>();
  const [locations, setLocations] = useState<string[]>();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const hasEquipmentSettingsAccess =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS],
      channel?._id,
      false
    );

  const canCreateEditEquipment =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT],
      channel?._id,
      false
    );

  const storedTableState = window.localStorage.getItem(
    TABLE_STATE_STORAGE_VARIABLE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;
  const [searchParams, setSearchParams] = useQueryString<EquipmentQueryString>(
    initialTableParams
  );

  usePersistedParams({
    searchParams,
    initialTableParams,
    tableStorageVariable: TABLE_STATE_STORAGE_VARIABLE,
  });

  const [getEquipmentSettings] = useLazyQuery(getEquipmentSettingsOnChannel, {
    onCompleted: ({ equipmentSettingsOnChannel }) => {
      if (equipmentSettingsOnChannel) {
        setCategories(compact(equipmentSettingsOnChannel.categories) || []);
        setLocations(compact(equipmentSettingsOnChannel.locations) || []);
      }
    },
  });

  const { data, loading: isLoading } = useQuery(searchEquipment, {
    variables: {
      channelId: channel?._id,
      // @ts-expect-error search type needs some attention
      search: {
        ...(searchParams.sortBy
          ? {
              sortBy: {
                key: searchParams.sortBy,
                dir: searchParams.sortDirection,
              },
            }
          : {}),
        ...(searchParams.keyword
          ? {
              search: {
                type: 'like',
                value: searchParams.keyword,
              },
            }
          : {}),
      },
      pagination: {
        start:
          ((searchParams?.page || 0) as number) *
          getPageSizeFromQueryString(searchParams?.pageSize),
        perPage: getPageSizeFromQueryString(searchParams?.pageSize),
      },
      location: searchParams?.location
        ? searchParams?.location?.split(',')
        : [],
      category: searchParams?.category
        ? searchParams?.category?.split(',')
        : [],
      status: searchParams?.status ? searchParams?.status?.split(',') : [],
      ...(searchParams?.warranty_expiration_date
        ? {
            warrantyExpirationDateStart: convertStringsToDates(
              searchParams?.warranty_expiration_date
            )?.startDate,
            warrantyExpirationDateEnd: convertStringsToDates(
              searchParams?.warranty_expiration_date
            )?.endDate,
          }
        : {}),
      ...(searchParams?.install_date
        ? {
            installDateStart: convertStringsToDates(searchParams?.install_date)
              ?.startDate,
            installDateEnd: convertStringsToDates(searchParams?.install_date)
              ?.endDate,
          }
        : {}),
    },
    onCompleted: () => {
      setEquipments(data?.searchEquipment?.equipment);
    },
  });

  const handleExportToCSV = async () => {
    try {
        setExportLoading(true);
        const { data } = await getClient().query({
          query: getEquipmentForExport,
          variables: {
            channelId: channel?._id,
          },
          fetchPolicy: 'network-only',
        });
        const equipmentCSVData = data?.getEquipmentForExport?.equipment || [];
        exportCSV(
          parseEquipments(equipmentCSVData),
          columns,
          `equipment-${new Date().toISOString()}.csv`
        );
        setExportLoading(false);
      } catch (error) {
        setExportLoading(false);
        window.Toast.show(t`web.admin.workOrder.table.export.error`, TOASTER_SECONDS);
      }
  };

  function parseEquipments(equipments: any[]) {
    return equipments.map((equipment: any) => {
      const row: { [key: string]: any } = {
        id: equipment?.id,
        name: equipment?.name,
        status: equipment?.status,
        category: equipment?.category,
        location: equipment?.location,
        notes: equipment?.notes,
        make: equipment?.make,
        model: equipment?.model,
        asset: equipment?.asset,
        serial: equipment?.serial,
        floor: equipment?.floor,
        suite: equipment?.suite,
        warranty_expiration_date: equipment?.warrantyExpirationDate,
        install_date: equipment?.installDate,
      };
      return row;
    });
  }

  const totalEquipment = data?.searchEquipment?.pageInfo?.total;
  useEffect(() => {
    if (totalEquipment) {
      setSearchParams({ total: totalEquipment });
    }
  }, [totalEquipment]);

  useEffect(() => {
    if (channel?._id) {
      getEquipmentSettings({
        variables: { channelId: convertToUUID(channel?._id) },
      });
    }
  }, [channel]);

  const columns = [
    {
      key: 'name',
      header: t('web.admin.serviceRequest.equipment.name.title'),
      type: 'text',
      disableSorting: false,
      disableVisibilityToggle: true,
      renderCell: (_: any, row: EquipmentQueryResponse) => (
        <Link
          to={routes.channelAdminWorkOrdersEquipmentDetails
            .replace(':id', channel?.slug)
            .replace(':equipmentId', row.id)}
        >
          {row.name}
        </Link>
      ),
      renderForCSV: (name: any) => name,
    },
    {
      key: 'category',
      header: t('web.admin.serviceRequest.equipment.category.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'location',
      header: t('web.admin.serviceRequest.equipment.location.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'status',
      header: t('web.admin.serviceRequest.equipment.status.title'),
      type: 'text',
      disableSorting: false,
      renderForCSV: (status: any) => status,
      renderCell: (status: string | undefined) => {
        return (
          <div>
            <ChipSelect.NonInteractive
              value={status!}
              type={
                status === EquipmentStatusOptions.ACTIVE
                  ? ChipStyle.Blue
                  : ChipStyle.Grey
              }
            />
          </div>
        );
      },
    },
    {
      key: 'make',
      header: t('web.admin.serviceRequest.equipment.make.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'model',
      header: t('web.admin.serviceRequest.equipment.model.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'asset',
      header: t('web.admin.serviceRequest.equipment.asset.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'serial',
      header: t('web.admin.serviceRequest.equipment.serial.title'),
      type: 'text',
      disableSorting: false,
    },
    {
      key: 'warranty_expiration_date',
      header: t(
        'web.admin.serviceRequest.equipment.warrantyExpirationDate.title'
      ),
      type: 'date',
      disableSorting: false,
    },
    {
      key: 'install_date',
      header: t('web.admin.serviceRequest.equipment.installDate.title'),
      type: 'date',
      disableSorting: false,
    },
  ];

  if (channel?.type === ActiveChannelTypeEnum.Property) {
    columns.push({
      key: 'floor',
      header: t('web.admin.serviceRequest.equipment.floor.title'),
      type: 'text',
      disableSorting: false,
    });
    columns.push({
      key: 'suite',
      header: t('web.admin.serviceRequest.equipment.suite.title'),
      type: 'text',
      disableSorting: false,
    });
  }

  const tableRows = useMemo(() => {
    const rows = equipments
      ? equipments.map((equipment: any) => ({
          id: equipment?.id,
          name: equipment?.name,
          status: equipment?.status,
          category: equipment?.category,
          location: equipment?.location,
          notes: equipment?.notes,
          make: equipment?.make,
          model: equipment?.model,
          asset: equipment?.asset,
          serial: equipment?.serial,
          floor: equipment?.floor,
          suite: equipment?.suite,
          warranty_expiration_date: equipment?.warrantyExpirationDate,
          install_date: equipment?.installDate,
        }))
      : [];
    return rows;
  }, [equipments]);

  const exportOptions = [
    {
      label: t`web.admin.serviceRequest.equipment.csvName`,
      onClick: handleExportToCSV,
      loading: exportLoading,
    },
  ];

  const hasFilterValue =
    searchParams?.location ||
    searchParams?.category ||
    searchParams?.status ||
    searchParams?.keyword ||
    searchParams?.warranty_expiration_date ||
    searchParams?.install_date;

  return (
    <div className={styles.equipments}>
      <Flex justify="space-between" align="center" mb={6}>
        <H3 className={styles.Heading}>
          {t('web.admin.serviceRequest.equipment.title')}
        </H3>
        <Flex gap={2}>
          {hasEquipmentSettingsAccess && (
            <Link
              data-cy="equipment-settings-link"
              to={routes.channelAdminWorkOrdersEquipmentSettings.replace(
                ':id',
                channel?.slug
              )}
            >
              <Button variant="outlined" size="medium">
                {t('web.admin.serviceRequest.settings')}
              </Button>
            </Link>
          )}
          {canCreateEditEquipment && (
            <Link
              to={routes.channelAdminWorkOrdersEquipmentCreate.replace(
                ':id',
                channel?.slug
              )}
              className={styles.createEquipmentLink}
            >
              <Button
                variant="activate-contained"
                className={styles.createEquipmentButton}
              >
                {t('web.admin.serviceRequest.equipment.add')}
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
      {(totalEquipment === undefined || Number(totalEquipment) === 0) &&
      !isLoading &&
      !hasFilterValue ? (
        <EmptyPageView
          icon="tools"
          title={t('web.admin.serviceRequest.equipment.emptyTable.title')}
          message={
            <DangerousTranslate
              translationKey="web.admin.serviceRequest.equipment.emptyTable.subtext"
              values={{}}
            />
          }
          primaryButton={{
            href: routes.channelAdminWorkOrdersEquipmentCreate.replace(
              ':id',
              channel?.slug
            ),
            label: t`web.admin.serviceRequest.equipment.add`,
          }}
          secondaryButton={
            dataImporterFlag
              ? {
                  href: routes.channelAdminDataImportNew.replace(
                    ':id',
                    channel?.slug
                  ),
                  label: t`web.admin.importer.workorders.list.newImport`,
                }
              : undefined
          }
        />
      ) : (
        <Table
          columns={columns}
          data={tableRows}
          hasKeywordFilter
          showColumnVisibility
          emptyMessage={t('web.admin.serviceRequest.emptyTable.message')}
          totalRows={searchParams.total}
          exportOptions={exportOptions}
          isLoading={isLoading}
          filters={[
            {
              key: 'location',
              label: t('web.admin.serviceRequest.equipment.location.title'),
              type: NativeFilterTypes.Multiselect,
              options:
                locations?.map((s: string) => ({ label: s, value: s })) || [],
            },
            {
              key: 'category',
              label: t('web.admin.serviceRequest.equipment.category.title'),
              type: NativeFilterTypes.Multiselect,
              options:
                categories?.map((s: string) => ({ label: s, value: s })) || [],
            },
            {
              key: 'status',
              label: t('web.admin.serviceRequest.equipment.status.title'),
              type: NativeFilterTypes.Multiselect,
              options: statusArray,
            },
            {
              key: 'warranty_expiration_date',
              label: t(
                'web.admin.serviceRequest.equipment.warrantyExpirationDate.title'
              ),
              type: NativeFilterTypes.DateRange,
            },
            {
              key: 'install_date',
              label: t('web.admin.serviceRequest.equipment.installDate.title'),
              type: NativeFilterTypes.DateRange,
            },
          ]}
          tableKey="workOrder.equipmentList"
          keywordFilterTooltip={t(
            'web.admin.serviceRequest.equipment.search.placeholder'
          )}
          queryStringsEnabled
          pagination="server"
        />
      )}
    </div>
  );
}

export default EquipmentList;
