import React, { useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import {
  ChannelSelectorButton,
  ControlMenu,
  ChannelCircleListView,
  Button,
  Stepper,
  ErrorMessage,
  ToggleView,
  ChannelProfileEdit,
} from 'components';
import { history } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { addChannelRelationship } from 'lane-shared/graphql/mutation';
import { castGraphQLObject, getDisplayName, pause } from 'lane-shared/helpers';
import { cloneChannel } from 'lane-shared/helpers/channel';
import { AddressType } from 'lane-shared/types/AddressType';
import {
  ChannelRelationshipTypeEnum,
  ChannelType,
  ChannelTypeEnum,
} from 'lane-shared/types/ChannelType';

import { ChannelSearchHierarchiesEnum } from 'components/lane/ChannelSearch';
import { ChannelSelectorModesEnum } from 'components/lane/ChannelSelector';
import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';
import { AdminPage } from 'components/layout';
import { H3, H4, H5, M, S } from 'components/typography';

import getChannelQuery from './getChannelQuery';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';

const allowedParentHierarchies = [ChannelSearchHierarchiesEnum.Parent];
const searchKey = 'NewChannelAndRelationshipChannelSelectorButton';
const STEPS = [
  'web.pages.portal.admin.channel.relationships.new.index.steps.selectParent',
  'web.pages.portal.admin.channel.relationships.new.index.steps.selectSetup',
  'web.pages.portal.admin.channel.relationships.new.index.steps.create',
];

const allowedTypes = [
  ChannelTypeEnum.Company,
  ChannelTypeEnum.Service,
  ChannelTypeEnum.Restaurant,
  ChannelTypeEnum.Retail,
  ChannelTypeEnum.Entertainment,
  ChannelTypeEnum.Professional,
  ChannelTypeEnum.Charity,
];

const TRANSLATION_KEYS = {
  stepOne: 'web.pages.portal.admin.channel.relationships.new.index.stepOne',
  stepOneDescription:
    'web.pages.portal.admin.channel.relationships.new.index.stepOneDescription',
  stepTwo: 'web.pages.portal.admin.channel.relationships.new.index.stepTwo',
  stepTwoDescription:
    'web.pages.portal.admin.channel.relationships.new.index.stepTwoDescription',
  stepThree: 'web.pages.portal.admin.channel.relationships.new.index.stepThree',
  stepThreeEditNewChannelInfo:
    'web.pages.portal.admin.channel.relationships.new.index.stepThreeEditNewChannelInfo',
  stepThreeEditNewChannelProfile:
    'web.pages.portal.admin.channel.relationships.new.index.stepThreeEditNewChannelProfile',
  nextButton:
    'web.pages.portal.admin.channel.relationships.new.index.nextButton',
  backButton:
    'web.pages.portal.admin.channel.relationships.new.index.backButton',
  createButton:
    'web.pages.portal.admin.channel.relationships.new.index.createButton',
  getChannelErrorTitle:
    'web.pages.portal.admin.channel.relationships.new.index.getChannelError.title',
  getChannelErrorMessage:
    'web.pages.portal.admin.channel.relationships.new.index.getChannelError.message',
  getChannelAddressError:
    'web.pages.portal.admin.channel.relationships.new.index.getChannelAddressError',
  createChannelErrorTitle:
    'web.pages.portal.admin.channel.relationships.new.index.createChannelError.title',
  createChannelErrorMessage:
    'web.pages.portal.admin.channel.relationships.new.index.createChannelError.message',
  createChannelSuccessToast:
    'web.pages.portal.admin.channel.relationships.new.index.createChannelSuccessToast',
  summaryTitle:
    'web.pages.portal.admin.channel.relationships.new.index.summaryTitle',
  location: 'web.pages.portal.admin.channel.relationships.new.index.location',
  type: 'web.pages.portal.admin.channel.relationships.new.index.type',
  parentChannel:
    'web.pages.portal.admin.channel.relationships.new.index.parentChannel',
  copiedFrom:
    'web.pages.portal.admin.channel.relationships.new.index.copiedFrom',
  summaryNote:
    'web.pages.portal.admin.channel.relationships.new.index.summaryNote',
};

export default function NewChannelAndRelationship() {
  const { user } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [parent, setParent] = useState<ChannelType | null>(null);
  const [newChannel, setNewChannel] = useState<ChannelType | null>(null);
  const [copyFrom, setCopyFrom] = useState<ChannelType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();

  const steps = STEPS.map(s => t(s));

  function updateNewChannel(props: Partial<ChannelType>) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prevState: ChannelType | null) ... Remove this comment to see the full error message
    setNewChannel(prevState => ({
      ...prevState,
      ...props,
      tags: [''],
    }));
  }

  function checkAddress(address: AddressType | null): boolean {
    return !!address?.geo?.[0];
  }

  function selectParent(channel: ChannelType | null) {
    setParent(channel);
  }

  useEffect(() => {
    if (parent?._id) {
      selectCopyFrom(parent);
    }
  }, [parent?._id]);

  async function selectCopyFrom(copyFrom: ChannelType | null) {
    let fullChannel: ChannelType;

    setError(null);
    setLoading(true);
    await pause();

    try {
      // first get a full copy of the channel we are copying
      const { data } = await getClient().query({
        query: getChannelQuery,
        variables: {
          id: copyFrom?._id,
        },
      });

      fullChannel = data.channel;
      fullChannel.tags = [''];
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.getChannelErrorTitle, {
          channelName: getDisplayName(copyFrom),
        }),
        message: t(TRANSLATION_KEYS.getChannelErrorMessage),
      });
      return;
    } finally {
      setLoading(false);
    }

    setCopyFrom(fullChannel);
    setError(
      checkAddress(channel!.address!)
        ? null
        : new Error(
            t(TRANSLATION_KEYS.getChannelAddressError, {
              channelName: getDisplayName(channel),
            })
          )
    );

    // use the construct channel to make a fully formed channel object
    setNewChannel(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'CloneChannelType' is not assigna... Remove this comment to see the full error message
      cloneChannel(fullChannel!, channel!.address!, parent!, user!._id)
    );
  }

  async function doCreate() {
    const relationship = {
      channel: castGraphQLObject(newChannel, true),
      relatedTo: {
        _id: channel!._id,
      },
      type: ChannelRelationshipTypeEnum.Tenant,
    };

    try {
      await pause();
      await getClient().mutate({
        mutation: addChannelRelationship,
        variables: { relationship },
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.createChannelSuccessToast, {
            channelName: newChannel?.name,
          })}
        </p>
      );
      history.push(
        routes.channelAdminRelationships.replace(':id', channel!.slug)
      );
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.createChannelErrorTitle, {
          channelName: getDisplayName(newChannel),
        }),
        message: t(TRANSLATION_KEYS.createChannelErrorMessage),
        error: err,
      });
    }
    setLoading(false);
  }
  return (
    <AdminPage className={styles.NewChannelAndRelationship}>
      <Stepper
        className={styles.stepper}
        steps={steps}
        active={currentStep}
        maxStep={currentStep}
        onClick={step => setCurrentStep(steps.findIndex(name => name === step))}
        disabled={loading}
      />

      {currentStep === 0 && (
        <>
          <div className={styles.search}>
            <H3 mb={4} bold>
              {t(TRANSLATION_KEYS.stepOne)}
            </H3>
            <H4 mb={4}>{t(TRANSLATION_KEYS.stepOneDescription)}</H4>
            <hr />
            <ChannelSelectorButton
              storageKey={`${searchKey}parent`}
              types={allowedTypes}
              hierarchies={allowedParentHierarchies}
              modes={[ChannelSelectorModesEnum.Search]}
              onChannelSelected={selectParent}
            />

            {parent && (
              <Link
                to={`${routes.channelAdmin.replace(
                  ':id',
                  parent.slug
                )}/profile`}
                target="_blank"
              >
                <ChannelCircleListView
                  className={styles.channelListView}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
                  channel={parent}
                />
              </Link>
            )}
          </div>

          <ControlMenu className={styles.menu}>
            <hr />
            <Button
              variant="contained"
              disabled={!parent}
              loading={loading}
              dataCy="tenantNext"
              onClick={async () => {
                setLoading(true);
                await pause(500);
                setCurrentStep(1);
                setLoading(false);
              }}
              endIcon={<Icon name="chevron-right" />}
            >
              {t(TRANSLATION_KEYS.nextButton)}
            </Button>
          </ControlMenu>
        </>
      )}

      {currentStep === 1 && parent && (
        <>
          <div className={styles.search}>
            <H3 mb={4} bold>
              {t(TRANSLATION_KEYS.stepTwo)}
            </H3>
            <H4 mb={4}>{t(TRANSLATION_KEYS.stepTwoDescription)}</H4>
            <hr data-margin-top={4} data-margin-bottom={2} />
            <ErrorMessage error={error} />
            <ChannelSelectorButton
              storageKey={`${searchKey}copyFrom`}
              types={allowedTypes}
              modes={[ChannelSelectorModesEnum.Search]}
              onChannelSelected={selectCopyFrom}
            />
            {copyFrom && (
              <Link
                to={`${routes.channelAdmin.replace(
                  ':id',
                  copyFrom.slug
                )}/profile`}
                target="_blank"
              >
                <ChannelCircleListView
                  className={styles.channelListView}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
                  channel={copyFrom}
                />
              </Link>
            )}
          </div>

          <ControlMenu className={styles.menu}>
            <Button
              variant="outlined-light"
              disabled={!parent}
              dataCy="tenantBack"
              onClick={() => setCurrentStep(0)}
              startIcon={<Icon name="chevron-left" />}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.backButton)}
            </Button>
            <hr />
            <Button
              variant="contained"
              dataCy="tenantNext"
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Error | null' is not assignable to type 'boo... Remove this comment to see the full error message
              disabled={!parent || error}
              onClick={async () => {
                setLoading(true);
                await pause(500);
                setCurrentStep(2);
                setLoading(false);
              }}
              endIcon={<Icon name="chevron-right" />}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.nextButton)}
            </Button>
          </ControlMenu>
        </>
      )}

      {currentStep === 2 && parent && (
        <>
          <div className={styles.creationSummary}>
            <div className={styles.summary}>
              <H4>{t(TRANSLATION_KEYS.summaryTitle)}</H4>
              <div className={styles.summaryItem}>
                <H5>{t(TRANSLATION_KEYS.location)}</H5>
                <M data-cy="locationName">{getDisplayName(channel)}</M>
              </div>
              <div className={styles.summaryItem}>
                <H5>{t(TRANSLATION_KEYS.type)}</H5>
                <M data-cy="channelType">{parent.type}</M>
              </div>
              <div className={styles.summaryItem}>
                <H5>{t(TRANSLATION_KEYS.parentChannel)}</H5>
                <M data-cy="parentChannel">{getDisplayName(parent)}</M>
              </div>
              <div className={styles.summaryItem}>
                <H5>{t(TRANSLATION_KEYS.copiedFrom)}</H5>
                <M data-cy="copiedFrom">{getDisplayName(copyFrom)}</M>
              </div>
              <S>{`*${t(TRANSLATION_KEYS.summaryNote)}`}</S>
            </div>
          </div>

          {newChannel && (
            <>
              <ToggleView
                title={t(TRANSLATION_KEYS.stepThreeEditNewChannelInfo)}
              >
                <ChannelInfoEdit
                  className={styles.panel}
                  channel={newChannel}
                  validation={null}
                  heading={t('web.admin.channel.new.heading', {
                    channel: getDisplayName(newChannel),
                  })}
                  onChannelUpdated={updateNewChannel}
                  channelForDataIdentifiers={newChannel}
                />
              </ToggleView>
              <ToggleView
                title={t(TRANSLATION_KEYS.stepThreeEditNewChannelProfile)}
              >
                <ChannelProfileEdit
                  className={styles.panel}
                  channel={newChannel}
                  onChannelUpdated={updateNewChannel}
                />
              </ToggleView>
            </>
          )}

          <ControlMenu className={styles.menu}>
            <Button
              variant="outlined-light"
              disabled={!parent}
              onClick={() => setCurrentStep(1)}
              startIcon={<Icon name="chevron-left" />}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.backButton)}
            </Button>
            <hr />
            <Button
              variant="contained"
              dataCy="createTenantButton"
              disabled={!parent}
              onClick={doCreate}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.createButton)}
            </Button>
          </ControlMenu>
        </>
      )}
    </AdminPage>
  );
}
