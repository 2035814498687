import React from 'react';

import { Button, TemplateLibrary, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { getDisplayName } from 'lane-shared/helpers';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './ChannelTemplateLibrary.scss';
import { LibraryTypeEnum } from 'lane-shared/types/libraries/LibraryTypeEnum';

export default function ChannelTemplateLibrary() {
  const location = useLocation();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return <Loading />;
  }

  return (
    <div className={styles.Templates}>
      <TemplateLibrary
        userLibraryEnabled={channel?.settings?.areUserTemplatesAllowed}
        library={{
          type: LibraryTypeEnum.Channel,
          _id: channel?._id,
          name: getDisplayName(channel),
        }}
        TemplateWrapper={({ template, children }: any) => (
          <Link
            to={{
              pathname: `templates/${template._id}/edit`,
              state: { from: location.pathname },
            }}
          >
            {children}
          </Link>
        )}
        additionalMenu={
          <Link
            to={{
              pathname: 'templates/new',
              state: { from: location.pathname },
            }}
          >
            <Button
              dataCy="newTemplate"
              variant="contained"
              size="small"
              className={styles.newTemplateButton}
            >
              {t('New Template')}
            </Button>
          </Link>
        }
      />
    </div>
  );
}
